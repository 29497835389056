/*
 * @Author: your name
 * @Date: 2021-09-28 14:19:48
 * @LastEditTime: 2021-11-18 18:51:50
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: /businesscs/Users/mac/Desktop/jyh-project/qt-www/src/router/modules/test-module.ts
 */
import { RouteConfig } from 'vue-router'

const routes: Array<RouteConfig> = [
    {
        path: '/:disIdentifier',
        redirect: '/disMirrorPage/:disIdentifier'
    },
    {
        path: '/disMirrorPage/:disIdentifier',
        name: 'disMirrorPage',
        component: () => import('@/views/disMirrorPage/disMirrorPage.vue')
    },
    // 商品详情页(镜像) - 分销商
    {
        path: '/disGoodsDetails/:goodsId(\\d+)/:disIdentifier',
        name: 'goodsDetailDisMirror',
        component: () => import('@/views/goods/detail/goodsDetailDisMirror.vue')
    },
    {
        path: '/html2Img/index',
        name: 'html2Img',
        component: () => import('@/views/html2Img/index.vue')
    },
    {
        path: '/',
        name: 'layout',
        component: () => import('@/views/layout/index.vue'),
        children: [
            {
                path: '',
                name: 'home',
                component: () => import('@/views/home/index.vue')
            }
            // {
            //     path: '/buy',
            //     name: 'buy',
            //     component: () => import('@/views/buy/index.vue')
            // },
            // {
            //     path: '/recovery',
            //     name: 'recovery',
            //     component: () => import('@/views/recovery/index.vue')
            // }
        ]
    }
    // {
    //     path: '/verification',
    //     name: 'verification',
    //     component: () => import('@/views/home/children/verification/index.vue')
    // },
    // // 下载页
    // {
    //     path: '/downLoad',
    //     component: () => import('@/views/home/children/downLoad/index.vue')
    // },
    // // 交易须知
    // {
    //     path: '/notice',
    //     name: 'notice',
    //     component: () => import('@/views/home/children/notice/index.vue')
    // },
    // // 通知列表
    // {
    //     path: '/noticeList',
    //     name: 'noticeList',
    //     component: () => import('@/views/notice/noticeList.vue')
    // },
    // // 通知详情
    // {
    //     path: '/noticeDetail/:noticeId(\\d+)',
    //     name: 'noticeDetail',
    //     component: () => import('@/views/notice/noticeDetail.vue')
    // },
    // // 商品详情页
    // {
    //     path: '/goodsDetail/:goodsId(\\d+)',
    //     name: 'goodsDetail',
    //     component: () => import('@/views/goods/detail/goodsDetail.vue')
    // },
    // // 商品详情页(镜像)
    // {
    //     path: '/goodsDetails/:goodsId(\\d+)',
    //     name: 'goodsDetailMirror',
    //     component: () => import('@/views/goods/detail/goodsDetailMirror.vue')
    // },

    // {
    //     path: '/generatorEntry',
    //     name: 'generatorEntry',
    //     component: () => import('@/views/generator/generatorEntry.vue')
    // },
    // {
    //     path: '/gameGenerator',
    //     name: 'gameGenerator',
    //     component: () => import('@/views/generator/gameGenerator.vue')
    // },
    // {
    //     path: '/ysGameGenerator',
    //     name: 'ysGameGenerator',
    //     component: () => import('@/views/generator/ysGameGenerator.vue')
    // },
    // {
    //     path: '/mirrorPage',
    //     name: 'mirrorPage',
    //     component: () => import('@/views/mirrorPage/mirrorPage.vue')
    // },

    // {
    //     path: '/wxPreview',
    //     name: 'wxPreview',
    //     component: () => import('@/views/wxPreview/index.vue')
    // },
    // {
    //     path: '/testDsbridge',
    //     name: 'testDsbridge',
    //     component: () => import('@/views/testDsbridge/test.vue')
    // },
]

export default routes
