/**
 * 指示日志消息的严重性。
 * 日志级别按严重性递增的顺序排列。所以“Debug”比“Trace”等更严重。
 *
 */
export enum LogLevel {
    /** 极低严重性诊断消息的日志级别. */
    Trace = 0,
    /** 调试错误. */
    Debug = 1,
    /** 消息. */
    Information = 2,
    /** 警告. */
    Warning = 3,
    /** 错误. */
    Error = 4,
    /** 严重错误. */
    Critical = 5,
    /** 最高日志级别。在配置日志记录以指示不应发出日志消息时使用. */
    None = 6
}

/** 日志工具 */
export default class Logger {
    private logLevel: LogLevel

    constructor(logLevel?: LogLevel) {
        this.logLevel = logLevel ?? LogLevel.Warning
    }

    getLogColor(logLevel: LogLevel) {
        switch (logLevel) {
            case LogLevel.Error:
            case LogLevel.Critical:
                return '#ed4014'
            case LogLevel.Warning:
                return '#ff9900'
            case LogLevel.Information:
                return '#2d8cf0'
            case LogLevel.Debug:
                return '#19be6b'
            case LogLevel.Trace:
            default:
                return '#c5c8ce'
        }
    }

    /** point log */
    // eslint-disable-next-line complexity
    log(logLevel: LogLevel, ...msg: any): void {
        if (logLevel < this.logLevel || logLevel >= LogLevel.None) return
        const point = (method: string, desc: string, ...msg: any) => {
            if (console.log.toString() === 'function log() { [native code] }') {
                const color: string = this.getLogColor(logLevel)
                // eslint-disable-next-line no-console
                console.log(
                    `%cQT%c ${desc} %c`,
                    'background: #515a6e; color:#fff;line-height:1.5em;font-size:12px;border-radius:3px 0px 0px 3px; padding: 0 0.4em 0 0.4em;',
                    `background: ${color}; color:#fff;line-height:1.5em;font-size:12px;border-radius:0px 3px 3px 0px; padding: 0 0.3em 0 0.3em;margin-right: 0.4em;`,
                    '',
                    ...msg
                )
            } else {
                ;(console as any)[method](`[QT] (${desc}) `, ...msg)
            }
        }
        switch (logLevel) {
            case LogLevel.Critical:
                return point('error', 'critical', ...msg)
            case LogLevel.Error:
                return point('error', 'error', ...msg)
            case LogLevel.Warning:
                return point('warn', 'warning', ...msg)
            case LogLevel.Information:
                return point('info', 'information', ...msg)
            case LogLevel.Debug:
                return point('log', 'debug', ...msg)
            case LogLevel.Trace:
                return point('log', 'trace', ...msg)
            default:
                return
        }
    }

    trace(...msg: any) {
        this.log(LogLevel.Trace, ...msg)
    }

    debug(...msg: any) {
        this.log(LogLevel.Debug, ...msg)
    }

    info(...msg: any) {
        this.log(LogLevel.Information, ...msg)
    }

    warn(...msg: any) {
        this.log(LogLevel.Warning, ...msg)
    }

    error(...msg: any) {
        this.log(LogLevel.Error, ...msg)
    }
}
