/*
 * @Author: your name
 * @Date: 2021-10-12 14:49:55
 * @LastEditTime: 2021-10-13 18:07:42
 * @LastEditors: your name
 * @Description: In User Settings Edit
 * @FilePath: /businesscs/Users/mac/Desktop/jyh-project/qt-www/src/apis/index.ts
 */
import { CommonApi } from './modules/common-api'
import { Goods } from './modules/goods'
import { HomePages } from './modules/home-pages'

export default class Apis {
    common: CommonApi = new CommonApi()
    home: HomePages = new HomePages()
    goods: Goods = new Goods()
}
