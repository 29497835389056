/*
 * @Author: your name
 * @Date: 2021-10-14 17:39:54
 * @LastEditTime: 2021-10-14 17:40:00
 * @LastEditors: your name
 * @Description: In User Settings Edit
 * @FilePath: /qt-www/src/utils/setRem.ts
 */
/**
 * html根节点设置font-size
 * @param {designwidth} Number 设计稿的宽度
 */
export const setRem = (designwidth: number) => {
    function fontSizeFun() {
        const fontSize = 100
        const relwidth = document.documentElement.clientWidth
        const relfontSize = (fontSize * relwidth) / designwidth + 'px'
        document.getElementsByTagName('html')[0].style.fontSize = relfontSize
    }
    fontSizeFun()
    window.onresize = fontSizeFun
}
