/*
 * @Author: your name
 * @Date: 2021-10-12 14:49:55
 * @LastEditTime: 2021-11-08 15:05:34
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: /businesscs/Users/mac/Desktop/jyh-project/qt-www/src/apis/modules/goods.ts
 */
import { request } from '@/define'

export interface IDemoParams {}

/** 首页模块 */
export class Goods {
    async reqQueryByGameId(options: IDemoParams) {
        return await request.post('/h5/goods/manage/queryHotGoods', options)
    }

    // 获取普通商品列表以及筛选
    async queryOrdinaryGoods(options: any) {
        options['rangeAttrListJson'] = JSON.stringify(options['rangeAttrListJson'])
        return await request.post('/h5/goods/manage/queryOrdinaryGoods', options)
    }

    // 获取游戏属性
    async getAttrContentList(options: IDemoParams) {
        return await request.post('/h5/goods/manage/listAttrInfo', options)
    }

    // 获取商品详情
    async getGoodsInfo(options: IDemoParams) {
        return await request.post('/h5/goods/manage/getGoodsInfo', options, {
            loadingContext: { message: '加载中', noDelay: true }
        })
    }

    // 获取普通商品列表以及筛选 - 分销商
    async disQueryOrdinaryGoods(options: any) {
        options['rangeAttrListJson'] = JSON.stringify(options['rangeAttrListJson'])
        return await request.post('/h5/distributor/goods/manage/queryOrdinaryGoods', options)
    }

    // 获取游戏属性 - 分销商
    async disGetAttrContentList(options: IDemoParams) {
        return await request.post('/h5/distributor/goods/manage/listAttrInfo', options)
    }

    // 获取商品详情 - 分销商
    async disGetGoodsInfo(options: IDemoParams) {
        return await request.post('/h5/distributor/goods/manage/getGoodsInfo', options, {
            loadingContext: { message: '加载中', noDelay: true }
        })
    }

    // 获取客服
    async getCustomerInfoByGameIds(options: IDemoParams) {
        return await request.post('/h5/customerService/getCustomerInfoByGameIds', options)
    }
}
