/*
 * @Author: your name
 * @Date: 2021-09-28 14:19:48
 * @LastEditTime: 2021-10-14 14:15:30
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: /businesscs/Users/mac/Desktop/jyh-project/qt-www/src/apis/modules/common-api.ts
 */
import { request } from '@/define'

export interface IDemoParams {}

/** 通用 接口模块 */
export class CommonApi {
    /** 示例接口 */
    async demo(options: IDemoParams) {
        return await request.post('/', options)
    }

    /** 分页查询公告 */
    async getNoticelist(options: IDemoParams) {
        return await request.post('/h5/announcement/queryAnn', options)
    }

    /** 根据公告id获取公告详情 */
    async getNoticeDetail(options: IDemoParams) {
        return await request.post('/h5/announcement/getAnnDetailById', options)
    }

    /** 获取游戏属性 */
    async getAttrContentList(options: IDemoParams) {
        return await request.post('/h5/gameManagement/getAttributeList', options)
    }

    // 获取原神游戏属性
    async getYuanshenAttrs() {
        return await request.post('/h5/gameManagement/getYuanshenAttrs')
    }

    /** 获取镜像网页banner */
    async getMirrorImage(options: IDemoParams) {
        return await request.post('/h5/imageBannerE/getImage', options)
    }

    /** 获取镜像网页banner - 分销商 */
    async getDisMirrorImage(options: IDemoParams) {
        return await request.post('/h5/distributor/imageBannerE/getImage', options)
    }

    /** 晴天小程序获取图片地址 */
    async getImgUrlById(options: any) {
        return await request.post('/h5/wechatApplets/getImgUrlById', options)
    }
}
