/*
 * @Author: your name
 * @Date: 2021-10-12 14:49:55
 * @LastEditTime: 2022-11-29 18:02:12
 * @LastEditors: 刘冰见
 * @Description: In User Settings Edit
 * @FilePath: /businesscs/Users/mac/Desktop/jyh-project/qt-www/src/apis/request.ts
 */
import Request, {
    dynamicProxy,
    env,
    filterNullValue,
    ILogger,
    loading,
    mock,
    transformRequest,
    transformResponse
} from '@ysfe/request'
import Cookie from 'js-cookie'
import { Toast } from 'vant'
import { VanToast } from 'vant/types/toast'

import { envs } from '@/data/Envs'
import { router } from '@/define'
console.log('%c [ envs ]-15', 'font-size:13px; background:pink; color:#bf2c9f;', envs)

/** 请求数据格式 */
export interface IRequestData {
    headers: any
    url: string
}
/** 响应数据格式 */
export interface IResponseData {
    message: string
    code: number
    data: any
}

/** 创建请求处理工具 */
export const createRequest = (
    logger: ILogger,
    envName?: 'production' | 'pre' | 'test' | 'test2' | 'dev' | 'dev2'
): Request<IResponseData> => {
    let loadingToast: VanToast | undefined
    console.log(env(envs, envName))
    return (
        new Request<IResponseData>({
            headers: { 'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8' },
            logger,
            only: true,
            mock: false // 当使用全局 mock 时, 将此项修改为 true
        })
            .use(env(envs, envName)) // 注入环境变量
            .use(mock()) // 注入mock
            .use(dynamicProxy()) // 注入动态代理
            .use(filterNullValue()) // 注入请求空值过滤
            // 切换loading显示, 采用默认的 500ms等待时间
            .use(
                loading((status: boolean, tip?: string) => {
                    // TODO 切换loading 显示
                    if (status) {
                        // 开
                        loadingToast = Toast.loading({
                            duration: 0,
                            message: tip ?? '加载中',
                            forbidClick: true
                        })
                    } else {
                        // 关
                        loadingToast?.clear()
                        loadingToast = undefined
                    }
                })
            )
            // 请求预处理
            .use(
                transformRequest((req: IRequestData) => {
                    if (req.url?.includes('distributor')) {
                        req.headers['QT-IDENTIFIER'] = router.currentRoute.params.disIdentifier || ''
                    } else {
                        req.headers['QT-IDENTIFIER'] = ''
                    }
                    return req
                })
            )
            // 响应处理
            .use(
                transformResponse((status: number, code: number, data: any) => {
                    // ? http 状态码 >= 400, 表示请求出错. 弹出报错信息即可
                    // if (status >= 400) throw new Error('请求出错了')
                    // ? 正常请求
                    if ([2000].includes(code)) return
                    // ? 未登录
                    if ([302].includes(code)) {
                        Cookie.remove('token', { expires: new Date().getTime() + 50 * 30 * 24 * 60 * 1000 * 1000 })
                        // TODO 下面这段逻辑, app 升级后废弃
                        // if (browser.isAndroidApp || (browser.isIosApp && browser.iosAppV > 121)) tokenExpired()
                        // TODO 弹出错误提示
                        // TODO 延迟 1000ms后, replace 到登录页
                    }
                    if ([undefined, 53].includes(code)) {
                        console.log('outSide interface (example => upload)')
                        return
                    }
                    throw data
                })
            )
    )
}
