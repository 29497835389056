/*
 * @Author: your name
 * @Date: 2021-09-28 14:19:48
 * @LastEditTime: 2021-10-13 17:53:28
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: /businesscs/Users/mac/Desktop/jyh-project/qt-www/src/define.ts
 */
/* 项目 全局属性定义 */

import Vconsole from 'vconsole'

import Apis from './apis'
import { createRequest } from './apis/request'
import { inMobile, isProduction, logLevel } from './data/Runtime'
import Router from './router'
import Store from './store'
import Logger from './utils/Logger'

export { createRequest } from './apis/request'

/** vconsole */
if (!isProduction && inMobile && !window.chrome) {
    new Vconsole({ theme: 'dark' })
}

/** 接口定义 */
export const apis: Apis = new Apis()

/** 路由定义 */
export const router: Router = new Router()

/** 服务层定义 */
export const store: Store = new Store()

/** 日志工具定义 */
export const logger: Logger = new Logger(logLevel)

/** 请求工具定义 */
export const request = createRequest(logger, 'dev')
