/*
 * @Author: your name
 * @Date: 2021-09-28 14:19:48
 * @LastEditTime: 2021-10-15 16:26:18
 * @LastEditors: your name
 * @Description: In User Settings Edit
 * @FilePath: /businesscs/Users/mac/Desktop/jyh-project/qt-www/src/router/index.ts
 */
import Vue from 'vue'
import VueRouter, { RouteConfig, RouterOptions } from 'vue-router'
Vue.use(VueRouter)
/** 使用 require.context() 动态加载路由 */
const moduleLoader = require.context('./modules', false, /(Module|-module)$/)
const routes: Array<RouteConfig> = moduleLoader
    .keys()
    .map((key) => {
        const m = moduleLoader(key).default
        if (m instanceof Array) return m
        else return [m]
    })
    .reduce((routes, m) => routes.concat(m), []) // 合并路由

/** 创建路由配置参数 */
const createRouteOptions = (options?: RouterOptions): RouterOptions => {
    // ? init
    if (!options) options = {}
    options = {
        mode: 'history',
        // base: process.env.BASE_URL,
        routes
    }

    return options
}

/** 扩展 路由定义 */
export default class Router extends VueRouter {
    constructor() {
        const options: RouterOptions = createRouteOptions()
        super(options)
    }
}
