import { request } from '@/define'

export interface IDemoParams {}

/** 首页模块 */
export class HomePages {
    // 轮播图
    async banner() {
        return await request.post('/h5/banner/getImage', {})
    }

    // 客服账号验证
    async customerVerify(options: IDemoParams) {
        return await request.post('/h5/customerService/customerVerify', options)
    }

    // 获取首页公告
    async getNotice() {
        return await request.post('/h5/announcement/ann', {})
    }

    // 获取客服验证文案
    async getDocumentPageInfo(options: IDemoParams) {
        return await request.post('/h5/document/getDocumentPageInfo', options)
    }
}
