import { env } from '@ysfe/request'

import { LogLevel } from '@/intf/ILogger'

import { envs } from './Envs'

/** 运行环境 */
const ua: string = navigator.userAgent

/* 设备信息 */

/** 是否在 移动端环境内
 * @description 注意: 此项仅做环境判断依据, 不作为视图层环境控制
 */
export const inMobile: boolean = /mobile/i.test(ua)
/** 是否 ios 设备 */
export const inIOS: boolean = /\(i[^;]+;( U;)? CPU.+Mac OS X/i.test(ua)
/** 是否 android 设备 */
export const inAndroid: boolean = /(android|Linux)/i.test(ua)

/** 是否微信浏览器环境 */
export const inWechat: boolean = /micromessenger/i.test(ua)
/** 是否 safari 浏览器 */
export const inSafari = /safari/i.test(ua) && !/(browser|android)/i.test(ua)
/** 是否 qq 内置浏览器 */
export const inQQ = /( qq|mqqbrowser)/i.test(ua)

/** 是否在 app 运行环境 */
export const inApp = /YSNativeSDK/i.test(ua)

/* 其他环境信息 */
/** 当前所处环境名称 | 服务环境 */
export const envName: string = env(envs).current.name
/** 当前是否在生产环境 */
export const isProduction: boolean = ['production'].includes(envName)
/** 当前是否在测试环境 */
export const isTest: boolean = ['test', 'test2'].includes(envName)
/** 当前是否处于本地运行状态 */
export const isLocal: boolean = process.env.command === 'serve'

/** 日志打印等级
 * 生产/预发 : 警告以上级别
 * 开发/测试 : debug及以上
 * 本地 : all
 */
export const logLevel: LogLevel = (() => {
    if (isLocal) return LogLevel.Trace
    else if (isProduction) return LogLevel.Warning
    return LogLevel.Debug
})()

/** 是否支持 webp 格式图片渲染 */
export const supportWebp: boolean = (() => {
    let isSupport: boolean = false
    const canvas = document.createElement('canvas')
    isSupport = canvas.toDataURL('image/webp').indexOf('data:image/webp') !== -1
    canvas.remove?.()
    return isSupport
})()
