/*
 * @Author: your name
 * @Date: 2021-10-12 14:52:36
 * @LastEditTime: 2021-10-18 17:53:54
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: /businesscs/Users/mac/Desktop/jyh-project/qt-www/src/main.ts
 */
import {
    Button,
    Collapse,
    CollapseItem,
    DropdownItem,
    DropdownMenu,
    Lazyload,
    List,
    NoticeBar,
    Popup,
    Sticky,
    Swipe,
    SwipeItem,
    Toast
} from 'vant'
import Vue from 'vue'
import VueClipboard from 'vue-clipboard2'

import { logger, router, store } from './define'
import { executeTime } from './utils/decorators/time'
import { setRem } from './utils/setRem'
import App from './views/App.vue'

console.log('resources loaded')
setRem(750)

/** 定义启动类 */
class Main {
    /** 主应用 | h5 */
    wap!: Vue

    constructor() {
        this.setup()
    }

    /** 启动函数
     *
     * @description 耗时分析方法, 在非生产环境时, 均生效.
     */
    @executeTime('启动耗时')
    async setup(): Promise<void> {
        // > 注入全局组件引用
        this.registerVuePrototypeRef()
        // 注册全局组件
        this.wap = new Vue({ name: 'app', store, router, render: (h) => h(App) }).$mount('#app')
        // @ 初始化完成后, 上报环境信息
        ;(window as any)['vue'] = this.wap
    }

    /** 注册全局组件引用 */
    private async registerVuePrototypeRef() {
        logger.trace('注册组件引用 - 开始')
        const { ValidationObserver, ValidationProvider } = await import('vee-validate')
        Vue.component('validation-provider', ValidationProvider)
        Vue.component('validation-observer', ValidationObserver)

        Vue.use(Lazyload)
        Vue.use(Swipe)
        Vue.use(SwipeItem)
        Vue.use(Popup)
        Vue.use(Toast)
        Vue.use(NoticeBar)
        Vue.use(List)
        Vue.use(DropdownMenu)
        Vue.use(DropdownItem)
        Vue.use(Sticky)
        Vue.use(Collapse)
        Vue.use(CollapseItem)
        Vue.use(Button)

        Vue.use(VueClipboard)
        logger.trace('注册组件引用 - 完成')
    }
}

export const main = new Main()
