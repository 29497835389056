import { Direction } from '@/intf/IDirection'

import { BaseModule } from '../base'

export interface IWinModel {
    direction: Direction
}

export default class WinModule extends BaseModule<IWinModel> {
    mql: MediaQueryList = window.matchMedia('(orientation: portrait)')

    initData(): IWinModel {
        this.createWindowListen()
        return {
            /** 横竖屏初始值 */
            direction: this.mql.matches ? Direction.Vertical : Direction.Horizontal
        }
    }

    get inWechat() {
        return true
    }

    /** 创建媒体属性监听 (屏幕方向变化监听) */
    createMediaListen() {
        // 根据屏幕方向变化, 更改窗口属性
        this.mql.addEventListener('change', (mql: any) => {
            const direction: Direction = mql.matches ? Direction.Vertical : Direction.Horizontal
            this.commit('direction', direction)
        })
    }

    /** 创建窗口变化监听 */
    createWindowListen() {
        // 窗口尺寸变更事件
        window.addEventListener('resize', () => this.onResize())
        // 网页 url 变化事件
        window.addEventListener('popstate', () => {
            //
        })
        // storage 数据变更事件
        window.addEventListener('storage', (e) => {
            //
        })
        // 阻断 ctrl 键 + 鼠标滚轮缩放网页用的
        document.addEventListener('mousewheel', (e: any) => {
            if (e.ctrlKey) e.preventDefault()
        })
        // 阻断window 原生的 拖拽事件
        window.addEventListener(
            'dragover',
            (e: any) => {
                e.preventDefault()
                e.stopPropagation()
            },
            false
        )
        // 阻断window 原生的 拖放事件
        window.addEventListener(
            'drop',
            (e: any) => {
                e.preventDefault()
                e.stopPropagation()
            },
            false
        )
        // 阻止右键菜单
        window.addEventListener('contextmenu', (e) => e.preventDefault(), true)
    }

    /** screen resize event handler */
    onResize() {
        // ! 暂时通过 ua 来判断当前窗口环境变化 h5 or pc
        const mobile: boolean = /mobile/i.test(navigator.userAgent)
        // if (inMobile !== mobile) location.reload()
    }
}
