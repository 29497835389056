import { IEnv } from '@ysfe/request'

/** 后端接口配置 */
export const envs: Array<IEnv> = [
    {
        name: 'production',
        rule: () => true,
        referer: 'https://www.qingtian668.com',
        baseURL: 'https://oms-server.qingtian668.com',
        order: -1
    },
    {
        name: 'pre',
        rule: /(www-pre\.qingtian668\.com)/,
        referer: 'https://www-pre.qingtian668.com',
        baseURL: 'https://oms-server-pre.qingtian668.com'
    },
    {
        name: 'test',
        rule: /(www-test\.qingtian668\.com)/,
        referer: 'http://www-test.qingtian668.com',
        baseURL: 'https://oms-server-test.qingtian668.com'
    },
    {
        name: 'dev',
        rule: /(www-dev\.qingtian668\.com|localhost|127\.0\.0\.1)/,
        referer: 'http://www-dev.qingtian668.com',
        // baseURL: 'http://qingtian668-dev-oms.server1.qingtian668.com',
        baseURL: 'http://oms-server-dev.qingtian668.com',
        // baseURL: 'https://mock.apifox.cn/m1/2653965-0-default',
        mockURL: '//'
        // order: process.env.NODE_ENV !== 'production' ? 1 : -1
    }
]
