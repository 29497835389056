import { isProduction } from '@/data/Runtime'

/** 方法执行时间分析 */
export const executeTime = (description: string = '耗时') => {
    return function (_target: any, _methodName: any, desc: any) {
        if (isProduction) return
        // 1. 保存当前的方法
        const oMethod = desc.value
        // 2. 改写方法
        desc.value = async function (...params: Array<any>) {
            try {
                console.time(description)
                await oMethod.apply(this, params)
            } finally {
                console.timeEnd(description)
            }
        }
    }
}
