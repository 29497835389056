
















import { Component, Prop, Vue } from 'vue-property-decorator'

/** H5 视图
 *
 * @author Halo
 * @create 2021-09-01 (星期三)
 */
@Component({ name: 'App', components: {} })
export default class extends Vue {
    /* props, data, getter */
    /* life event: [mounted, beforeDestory] */
    /* methods */
}
