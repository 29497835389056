import { StoreModule } from '@ysfe/store'

import { apis, router } from '@/define'

import Store from '.'

/** store module 抽象类 */
export abstract class BaseModule<T> extends StoreModule<Store, T> {
    /** 接口集合 */
    get $apis() {
        return apis
    }

    /** 路由集合 */
    get $router() {
        return router
    }

    /** 当前路由 */
    get $route() {
        return router.currentRoute
    }
}
